/** 配列の中からランダムに1つ取り出す。 */
export const pickup = <T>(array: readonly T[]) => {
  const i = Math.floor(Math.random() * array.length);
  return array[i];
};

/** 文字列の先頭の文字を取得する。 */
export const first = (arg: string) => {
  return arg.slice(0, 1);
};
