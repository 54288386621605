import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import responses from 'data/status-codes.json';
import { pickup, first } from 'utils';

type Response = {
  code: string;
  status: string;
  enText: string;
  enUrl: string | null;
  jaText: string;
  jaUrl: string | null;
};

const langs = ['en', 'ja'] as const;
type Lang = typeof langs[number];

const numbers = ['100', '200', '300', '400', '500'] as const;
type Number = typeof numbers[number];

type PresentationProps = {
  response: Response;
  _ref: React.MutableRefObject<HTMLDivElement | null>;
  lang: Lang;
  selectLang: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  number: Number | null;
  selectNumber: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
} & { className?: string };

const Presentation = (props: PresentationProps) => (
  <div className={props.className}>
    <div className="main-container">
      <h1>{props.response.code}</h1>
      <div ref={props._ref}>
        <h2>{props.response.status}</h2>
        <div>
          <p className="text">
            {props.lang === 'en' ? props.response.enText : props.response.jaText}
          </p>
          <p className="url">
            <a
              href={(props.lang === 'en' ? props.response.enUrl : props.response.jaUrl) ?? ''}
              target="_blank"
              rel="noreferrer"
            >
              {props.lang === 'en' ? props.response.enUrl : props.response.jaUrl}
            </a>
          </p>
        </div>
      </div>
    </div>
    <div className="sub-container">
      <div className="lang-block">
        {langs.map((l) => {
          return (
            <div key={l} className={props.lang === l ? 'selected' : ''}>
              <input
                type="radio"
                name="l-radios"
                id={l}
                value={l}
                onClick={props.selectLang}
                defaultChecked={l === 'en'}
              />
              <label htmlFor={l}>{l.toUpperCase()}</label>
            </div>
          );
        })}
      </div>
      <div className="number-block">
        {numbers.map((n) => {
          return (
            <div key={n} className={props.number === n ? 'selected' : ''}>
              <input type="radio" name="n-radios" id={n} value={n} onClick={props.selectNumber} />
              <label htmlFor={n}>{n}</label>
            </div>
          );
        })}
      </div>
    </div>
  </div>
);

const appear = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1 }
`;

const StyledComponent = styled(Presentation)`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  /* Mobile : Tablet : Desktop = 1 : 1.5 : 2 */
  @media (max-width: 768px) {
    padding: 16px;
    > div.main-container {
      padding: 0 0; /* M : T : D = 0 : 120 : 240 */
      > h1 {
        font-size: 48px; /* M : T : D = 48 : 64 : 80 */
        margin-top: 40px; /* M : T : D = 40 : 80 : 120 */
      }
      > div {
        margin-top: 24px; /* M : T : D = 24 : 32 : 40 */
        > h2 {
          font-size: 24px; /* M : T : D = 24 : 32 : 40 */
        }
        > div {
          margin-top: 24px; /* M : T : D = 24 : 32 : 40 */
          > p.text {
            font-size: 16px; /* M : T : D = 16 : 20 : 24 */
          }
          > p.url {
            margin-top: 16px; /* M : T : D = 16 : 24 : 32 */
            font-size: 12px; /* M : T : D = 12 : 14 : 16 */
          }
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    padding: 24px;
    div.main-container {
      padding: 0 120px;
      > h1 {
        font-size: 64px;
        margin-top: 80px;
      }
      > div {
        margin-top: 32px;
        > h2 {
          font-size: 32px;
        }
        > div {
          margin-top: 32px;
          > p.text {
            font-size: 20px;
          }
          > p.url {
            margin-top: 24px;
            font-size: 14px;
          }
        }
      }
    }
  }
  @media (min-width: 1024px) {
    padding: 32px;
    div.main-container {
      padding: 0 240px;
      > h1 {
        font-size: 80px;
        margin-top: 120px;
      }
      > div {
        margin-top: 40px;
        > h2 {
          font-size: 40px;
        }
        > div {
          margin-top: 40px;
          > p.text {
            font-size: 24px;
          }
          > p.url {
            margin-top: 32px;
            font-size: 16px;
          }
        }
      }
    }
  }
  > div.main-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      opacity: 0;
      &.appear {
        animation: ${appear} 0.5s forwards;
      }
      > h2 {
        font-weight: bold;
      }
      > div {
        line-height: 1.5;
        > p.url {
          text-align: right;
          > a {
            text-decoration: underline;
          }
        }
      }
    }
  }
  > div.sub-container {
    display: flex;
    justify-content: space-between;
    > div.lang-block {
      display: flex;
      > div {
        padding: 4px;
        margin: 0 4px;
        border-radius: 2px;
        background-color: #f3f3f3;
        &.selected {
          background-color: #ffff90;
        }
        > input {
          display: none;
        }
      }
    }
    > div.number-block {
      display: flex;
      > div {
        padding: 4px;
        margin: 0 4px;
        border-radius: 2px;
        background-color: #f3f3f3;
        &.selected {
          background-color: #ffff90;
        }
        > input {
          display: none;
        }
      }
    }
  }
`;

type ContainerProps = {};

const Container = (_: ContainerProps) => {
  const [response, setResponse] = useState<Response | null>(null);
  const property = useMemo(() => Object.entries(responses), []);

  const [lang, setLang] = useState<Lang>('en');
  const storageKey = 'lang';

  const selectLang = useCallback((e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    const lang_ = e.currentTarget.value as Lang;
    setLang(lang_);
    window.localStorage.setItem(storageKey, lang_);
  }, []);

  useEffect(() => {
    const lang = window.localStorage.getItem(storageKey);
    if (lang === 'en' || lang === 'ja') {
      setLang(lang);
    }
  }, []);

  const [number, setNumber] = useState<Number | null>(null);

  const selectNumber = useCallback(
    (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
      const number_ = e.currentTarget.value as Number;
      if (number === number_) {
        e.currentTarget.checked = false;
        setNumber(null);
      } else {
        setNumber(number_);
      }
    },
    [number]
  );

  const ref = useRef<HTMLDivElement | null>(null);
  const intervalId = useRef<NodeJS.Timer>();
  const timeoutId = useRef<NodeJS.Timer>();

  const bindUpdateResponse = useCallback((property_: typeof property, intervalTime: number) => {
    return () => {
      const [key, value] = pickup(property_);
      const { status, enText, enUrl, jaText, jaUrl } = value;
      setResponse({ code: key, status, enText, enUrl, jaText, jaUrl });
      const className = 'appear';
      const timeoutTime = intervalTime * 0.25;
      if (ref !== null && ref.current !== null) {
        ref.current.classList.remove(className);
      }
      timeoutId.current = setTimeout(() => {
        if (ref !== null && ref.current !== null) {
          ref.current.classList.add(className);
        }
      }, timeoutTime);
    };
  }, []);

  useEffect(() => {
    if (intervalId.current !== undefined) {
      clearInterval(intervalId.current);
    }
    let property_ = property;
    if (number !== null) {
      const firstNumber = first(number);
      property_ = property.filter(([key]) => {
        return first(key) === firstNumber;
      });
    }
    const intervalTime = 5000;
    const updateResponse = bindUpdateResponse(property_, intervalTime);
    updateResponse(); // 初回実行 - setInterval の初回実行は指定時間経過後のため
    intervalId.current = setInterval(updateResponse, intervalTime); // 定期実行
  }, [property, number, bindUpdateResponse]);

  if (response === null) return null; // <></>
  return (
    <StyledComponent
      response={response}
      _ref={ref}
      lang={lang}
      selectLang={selectLang}
      number={number}
      selectNumber={selectNumber}
    />
  );
};

export const App = Container;
